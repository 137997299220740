body {
  margin: 0;
  font-family: "Kanit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ========================================
GLOBAL STYLING
======================================== */

* {
  font-family: "Kanit", sans-serif;
}

a {
  color: #08a4bd;
}
a:hover {
  color: #fb3640;
}

/* Headers */
h1 {
  font-family: "Kanit", sans-serif;
  font-weight: bolder;
  font-size: 64px;
}

h2 {
  font-family: "Kanit", sans-serif;
  font-weight: bolder;
  font-size: 48px;
}

/* -- BACKGROUND STYLES -- */
.bg-custom-pink {
  background-color: #f06c9b;
}
.bg-custom-orange {
  background-color: #f6ae2d;
}
.bg-custom-green {
  background-color: #60a561;
}
.bg-custom-blue {
  background-color: #62bec1;
}
.bg-custom-pink {
  background-color: #f06c9b;
}
.bg-custom-yellow {
  background-color: #fff275;
}
.bg-custom-onyx {
  background-color: #444444;
}

/* ========================================
ABOUT ME STYLING
======================================== */

.bio-img {
  width: 100%;
}

@media only screen and (max-width: 500px) {
  .bio-img {
    width: 100%;
  }
}

/* ========================================
MY STRENGTHS STYLING
======================================== */

/* StrengthFinders Card Title */
.strengthTitle {
  font-weight: bold;
  font-size: 1.25em;
}
