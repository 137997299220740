/* ========================================
FOOTER STYLING
======================================== */

footer {
  height: 25vh;
  width: 100%;
  bottom: 0;
  color: white;
  background-color: #0b0b0b;
  text-align: center;
  position: relative;
}

.footer-accent {
  background: -webkit-linear-gradient(
    339deg,
    rgba(255, 74, 118, 1) 0%,
    rgba(235, 126, 62, 1) 50%,
    rgba(245, 51, 78, 1) 100%
  );
  height: 1vh;
  /* margin-bottom: 1vh; */
}

.Footer__Icon {
  padding: 5px;
  color: white;
}

.Footer__Icon:hover {
  color: rgba(235, 126, 62, 1);
}

.Footer__Logo {
  margin: 0;
  padding: 15px 0px 5px 0px;
}

.Footer__Copyright {
  position: absolute;
  width: 100%;
  color: #fff;
  line-height: 40px;
  text-align: center;
  bottom: 0;
}
