/* ========================================
PORTFOLIO CONTENT STYLING
======================================== */

.portfolioBtn:hover {
  color: white;
}

.portfolio-img {
  width: 100%;
}

.card-img-top {
  width: 100%;
  border-radius: 20px;
}

.projectCard {
  padding: 30px;
}

.projectCard__Details {
  padding: 20px;
}

.projectCard__Title {
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
}
