.bio__container {
  z-index: 4;
  background-color: white;
}

.bio__details {
  width: 100%;
  padding: 10%;
  font-size: 1rem;
}

.bio__portrait {
  width: 75%;
  border-radius: 50px;
}

.about__buttons__block {
  margin: auto auto;
  width: 100%;
  display: block;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .bio__container {
    padding: 30px 20px 50px 20px;
  }
  .bio__details {
    width: 100%;
    padding: 5%;
    font-size: 1rem;
  }
}
@media screen and (min-width: 992px) {
  .bio__container {
    padding: 30px 30px 50px 30px;
  }
  .bio__details {
    width: 100%;
    padding: 10%;
    font-size: 1rem;
  }
}
@media screen and (min-width: 1200px) {
  .bio__container {
    padding: 30px 200px 80px 200px;
  }
  .bio__details {
    width: 100%;
    padding: 10%;
    font-size: 1rem;
  }
  .about__buttons__block {
    margin: auto auto;
    width: 100%;
    display: block;
    text-align: left;
  }
}
