.PortfolioSection__Container {
  background: rgb(219, 219, 219);
  background: linear-gradient(
    350deg,
    rgba(219, 219, 219, 1) 0%,
    rgba(255, 255, 255, 1) 28%,
    rgba(240, 240, 240, 1) 100%
  );
  padding: 20px;
  z-index: 4;
}

@media screen and (min-width: 768px) {
  .PortfolioSection__Container {
    padding: 30px 20px 50px 20px;
  }
}
@media screen and (min-width: 992px) {
  .PortfolioSection__Container {
    padding: 30px 30px 50px 30px;
  }
}
@media screen and (min-width: 1200px) {
  .PortfolioSection__Container {
    padding: 30px 50px 80px 50px;
  }
}
