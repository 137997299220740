.strength__card {
  width: 100%;
  max-width: 500px;
  padding: 40px 25px;
}

.strength__number__div {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 8px;

  background: #aaa;
  border: 2px solid #666;
  color: #666;
  text-align: center;

  font-size: 32px Arial;
}

.strength__name {
  font-size: 24px;
  padding-top: 25px;
}

.strength__icon {
  background: -webkit-linear-gradient(
    339deg,
    rgba(255, 74, 118, 1) 0%,
    rgba(235, 126, 62, 1) 50%,
    rgba(245, 51, 78, 1) 100%
  );
  -webkit-background-clip: text;
  background-clip: none;
  -webkit-text-fill-color: transparent;
}

.strength__description {
  text-align: left;
}

@media (min-width: 768px) {
  .strength__card {
    width: 100%;
    height: 350px;
  }
}
@media (min-width: 992px) {
  .strength__card {
    width: 300px;
    height: 350px;
  }
}
@media (min-width: 1200px) {
  .strength__card {
    width: 350px;
    height: 325px;
  }
}
