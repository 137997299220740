/* ========================================
GLOBAL STYLING
======================================== */

* {
  font-family: "Kanit", sans-serif;
}

a {
  color: white;
}
a:hover {
  color: rgba(235, 126, 62, 1);
}

/* Headers */
h1 {
  font-family: "Kanit", sans-serif;
  font-weight: bolder;
  font-size: 64px;
}

h2 {
  font-family: "Kanit", sans-serif;
  font-weight: bolder;
  font-size: 48px;
}

/* -- BACKGROUND STYLES -- */
.bg-custom-pink {
  background-color: #f06c9b;
}
.bg-custom-orange {
  background-color: #f6ae2d;
}
.bg-custom-green {
  background-color: #60a561;
}
.bg-custom-blue {
  background-color: #62bec1;
}
.bg-custom-pink {
  background-color: #f06c9b;
}
.bg-custom-yellow {
  background-color: #fff275;
}
.bg-custom-onyx {
  background-color: #444444;
}

/* CONTAINER */
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

/* CONTENT CARD */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 20px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* STRENGTH CARD */
.strength-card {
  border: 1px solid black;
  border-radius: 10px;
  padding: 25px 15px 0 15px;
  min-height: 250px;
}

/* .portfolio__container {
  margin: 50px auto;
} */
